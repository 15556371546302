import React from 'react';
import {
  ComponentPreview,
  Link,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  PlatformTableRow,
  Section,
} from 'docComponents';
import { Text } from 'hudl-uniform-ui-components';
import textPreviewData from '../../../../data/previews/text.yml';
import pageHeroData from '../../../../data/pages/components.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Text"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Type" tierThree="Text" />

      <PageNavigation>
        <PageNavigationLink>Size</PageNavigationLink>
        <PageNavigationLink>Style</PageNavigationLink>
        <PageNavigationLink>Microcopy</PageNavigationLink>
        <PageNavigationLink>Platform</PageNavigationLink>
      </PageNavigation>

      <Section title="Size">
        <Paragraph>
          The level of text you select should provide the appropriate hierarchy
          and visual coherency within the interface.
        </Paragraph>
        <ComponentPreview
          name="TextSize"
          layout="default"
          previewData={textPreviewData.levels}>
          <Text>
            If sending a check, please include the invoice number in the memo.
          </Text>
        </ComponentPreview>
      </Section>

      <Section title="Style">
        <Paragraph>
          The tight variation of text decreases the line height to save space.
          Because it is more difficult to read, tight is not intended for long
          blocks of copy.
        </Paragraph>
        <ComponentPreview
          name="TextStyle"
          layout="default"
          previewData={textPreviewData.styles}>
          <Text>
            If sending a check, please include the <br />
            invoice number in the memo.
          </Text>
        </ComponentPreview>
      </Section>

      <Section title="Microcopy">
        <Paragraph>
          Use the{' '}
          <Link href="/words/content-elements/labels-non-label-text">
            non-label guidelines
          </Link>{' '}
          when typesetting text.
        </Paragraph>
      </Section>

      <Section title="Platform">
        <PlatformTable footerMessage="No guidelines exist for Windows (yet).">
          <PlatformTableRow platform="web" />
          <PlatformTableRow platform="apple" />
          <PlatformTableRow platform="android" />
        </PlatformTable>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
